import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Grommet, Text } from 'grommet'
import { AnchorButton } from '../components'
import { PageHelmet } from '../components/common'

const theme = {
  global: {
    font: {
      family: "'Muli', sans-serif",
      size: '1em',
    },
    colors: {
      brand: "#222"
    }
  },
  button: {
    size: {
      large: {
        border: {
          radius: "8px",
          color: "#222"
        }
      }
    }
  }
}

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {in: [
        "error-404.png",
      ]}}) {
        images: edges {
          node {
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const fluidImage = (path) => {
    const images = data.allFile.images
    const imageNode = images.find(item => item.node.relativePath === path)
    return imageNode && imageNode.node.childImageSharp.fluid
  }

  return (
    <Grommet full={true} theme={theme}>
      <PageHelmet title="404 Not Found" titleTemplate="Hippobyte" />
      <Box fill justify="center" align="center" pad={{ bottom: "xlarge", horizontal: "xlarge" }}>
        <Box width="medium">
          <Img fluid={fluidImage("error-404.png")} />
        </Box>
        <Text
          size="xlarge"
          margin={{ vertical: "large" }}
          weight="900"
          color="#222"
        >
          Oops! Page Not Found.
        </Text>
        <AnchorButton 
          primary
          size="large"
          label="Go to Homepage"
          path="/"
        />
      </Box>
    </Grommet>
  )
}

export default NotFoundPage
